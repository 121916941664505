<template>
  <div class="container">
    <div class="floats_right">
      <p><a @click="()=>{
            contentHtml = textInfo.rule
            contentHtmlModal = true
          }">规则 ></a></p>
      <p><router-link to="/service">客服 ></router-link></p>

    </div>

    <div class="main">

      <div class="mobile-form">
      `<div class="phone">
        <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入您的手机号码">
          <template #left-icon>
            <van-icon class-prefix="icon" name="mobile" size="20" color="#888"/>
          </template>
        </van-field>
      </div>
      <div class="paybtn"><van-button round block @click="onsubmit">立即充值</van-button></div>

      <!--<div class="tips">
        <van-checkbox v-model="isread" icon-size="16px" checked-color="#52c100">我已阅读并同意</van-checkbox>
        <a @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《隐私协议》</a>
        <a @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>
      </div>-->`
      </div>
    </div>

    <div class="foot">
      <p>客服电话：4006178158</p>
      <p>{{textInfo.company_name}}</p>
    </div>
    <div class="content" v-html="detail.detail"></div>
    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#52c100" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <payment :data="json" :money="money" ref="payment" v-if="money.price" :showRetention="1"/>
  </div>
</template>
<script>
  import moment from 'moment'
  import payment from './components/payment'
  import {GetPayConfig,GetUserPrivate} from './services/services'
  export default {
    name:'redbox',
    components: {payment},
    data () {
      return {
        money:{},
        json:{
          id:0,
          pay_way:0,
          order_sn:'',
          phone:'',
          account:'',
          url:location.href
        },
        detail:{},
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
        timer:null
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }

      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    mounted(){
      this.findpayConfig()
      this.finduserprivate()
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      moment,
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = this.detail = result.data.data
            let payWay = this.detail.payConfig[0].payWay
            let pay=this.detail.payConfig.find(item=>item.payWay == payWay)
            const {pwyAccount} = pay
            this.json.pay_way=payWay
            this.json.account=pwyAccount

            let money=this.detail.goodsArray.find(item=>item.status==1)
            this.money=money

            this.json.id=money.id

            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        let params = {
          ...this.$route.query,
          phone:btoa(this.json.phone)
        }
        // this.$refs.payment.onsubmit(this.json)
        this.$router.push({path:'/pay100',query:params})
      }

    }
  }

</script>
<style scoped lang="less">
  .container{min-height:100vh;background:#000;position:relative;
    .floats_right{position:fixed;right:0px;top:20px;z-index:9;
      p{padding:6px 0px;
        a{background:#f9dc98;color:#aa733e;border-radius:20px 0px 0px 20px;padding:3px 8px 4px 18px;display:inline-block;font-size:12px;}
      }
    }

    .main{position:relative;background:url('@{static-dir}redbox_bg.png') no-repeat center center;background-size:100% auto;height:100vh;padding-top:60vh;box-sizing:border-box;-moz-box-sizing:border-box;-webkit-box-sizing:border-box;
      .mobile-form{padding:0px 56px;}
      .phone{
        .van-cell{border-radius:8px;font-size:16px;line-height:30px;padding:8px 10px 6px 10px;}
      }
      .paybtn{margin-top:20px;text-align:center;
        .van-button{background:#fcc805;height:50px;line-height:50px;-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;border:none;
          .van-button__text{color:#333526;font-size:28px;font-weight:700;}
        }
      }
      .tips{margin-top:8px;font-size:12px;display:flex;
        span{display:inline-block;}
        a{color:#666;}
        /deep/.van-checkbox__label{font-size:12px;color:#888;}
      }
    }
    .content{color:#ddd;padding:20px;font-size:12px;
      /deep/p{padding:4px 0px;}
    }

    .foot{color:#fff;text-align:center;line-height:24px;}
  }

  @keyframes paybtn_scalc{
    0% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
    50% {
      -webkit-transform:scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform:scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes ro{
    0%{
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn);
    }
  }

</style>
